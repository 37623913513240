<template>
    <div class="px-8">
        <div class="flex justify-center">
            <p class="bg-green-300 border border-green-500 rounded-lg my-2 py-2 px-8 text-white">
                {{ $t('calendar.future_services_for_next_4_months') }}
            </p>
        </div>
        <div v-for="event in eventsData" :key="event.uuid" class="flex justify-between relative border-b border-gray-200 p-2">
            <div>
                <div class="flex">
                    <p v-for="service in event.services" :key="service.uuid" class="mr-1">
                        {{ service.name }},
                    </p>
                </div>
                <div class="flex">
                    <p v-for="employee in event.employees" :key="employee.uuid" class="mr-1">
                        {{ employee.name }} {{ employee.surname }},
                    </p>
                </div>
            </div>
            <p>
                {{ event.started_event_at }} - {{ event.ended_at }}, {{ event.date }}
            </p>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        eventUuid: {
            type:    String,
            default: null,
        },
    },

    data() {
        return {
            eventsData: [],
        };
    },

    created() {
        this.getAll();
    },

    methods: {
        async getAll() {
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/services/calendar/${this.eventUuid ? this.eventUuid : this.$store.state.calendar.eventToEditUuid}/future_events`);
            this.eventsData = data;
        },
    },
};
</script>
